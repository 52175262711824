<script>
  import axios from "axios";
  import { onMount } from "svelte";
  import Modal from "components/Modal.svelte";

  let recipes = [];
  let filteredRecipes = [];
  let selectedRecipe = null;
  let currentPage = 1;
  let itemsPerPage = 5;
  let maxPage = 0;
  let searchValue = "";
  let idEditModal = 0;
  let recetaModal;
  let idRecetaCatalog = 0;
  let ingredients = "";
  let ingredientSelected = "";

  const dificultades = [
    { id: 91, name: "Fácil" },
    { id: 92, name: "Intermedio" },
    { id: 93, name: "Avanzado" },
  ];

  let ModalContent = 0;
  let formData = {};
  const token =
    "eyJhbGciOiJIUzUxMiJ9.eyJyb2xlcyI6WyJST0xFX1VTRVIiXSwibmFtZSI6IkpvcmdlIENhbGRlcm9uIiwiaWQiOjcsImVtYWlsT3JQaG9uZSI6ImNhbGRlcm9uLmpvcmdlNEBnbWFpbC5jb20iLCJzdWIiOiI3IiwiaWF0IjoxNzEyNzEwNTExLCJleHAiOjE3NDQyNDY1MTF9.bvUq4kgZMK6Slv8NtS8ER4lL-qLsPsBcWpagpvyNopcee7vLK46k_eQrTngE2aAMw6HAWm2K372ftQZBuuqotg";

  // Busca las recetas con el input search
  const searchRecipe = (event) => {
    searchValue = event.target.value;
    currentPage = 1;
    loadRecipes();
  };

  // Carga recetas en la lista
  const loadRecipes = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/recipe/backoffice?page=${currentPage}&size=${itemsPerPage}&val=${searchValue}`,
        { headers }
      );
      recipes = response.data.data.content;
      filteredRecipes = [...recipes];
      maxPage = response.data.data.totalPages;
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  // cerrar modal
  function closeModal() {
    selectedRecipe = null;
  }
  // eliminar receta
  const deleteRecipe = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const url = `https://sandbox.honey-dates.com/api/v1/recipe/${selectedRecipe.id}`;
      const response = await axios.delete(url, { headers });

      loadRecipes();
      closeModal();
    } catch (err) {
      console.error(err);
    }
  };

  // Ver Receta en Modal
  const recipeModal = async () => {
    selectedRecipe.imageCoverUrl = selectedRecipe.imageCoverUrl.replace(
      /^.*\//,
      ""
    );
    selectedRecipe.imageCroppedUrl = selectedRecipe.imageCroppedUrl.replace(
      /^.*\//,
      ""
    );
    selectedRecipe.videoUrl = selectedRecipe.videoUrl.replace(/^.*\//, "");
    selectedRecipe.translate = JSON.parse(selectedRecipe.translate);

    formData = selectedRecipe;
    console.log("formdata", formData);
  };

  // Ver Receta en Modal
  const EnviarRecetaEditadaModal = async () => {
    console.log(formData);

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };

      const response = await axios.put(
        `https://sandbox.honey-dates.com/api/v1/recipe/${formData.id}`,
        formData,
        { headers }
      );
      loadRecipes();
      closeModal();
      console.log(response);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // Paginación
  const nextPage = async () => {
    currentPage++;
    await loadRecipes();
  };

  const previousPage = async () => {
    if (currentPage > 1) {
      currentPage--;
      await loadRecipes();
    }
  };

  // funcion para cargar catalogos
  const obtenerCatalogItems = async (idCatalog) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };
      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/catalog/type/${idCatalog}`,
        { headers }
      );

      return response.data.data; // Devuelve solo los datos del catálogo
    } catch (error) {
      console.error("Error al obtener los ítems del catálogo:", error);
      throw error; // Lanza nuevamente el error para manejarlo en la parte que llama a esta función
    }
  };

  // plan de dieta
  // cargar al modal
  let allDietPlan;
  async function selectDietPlan() {
    allDietPlan = await obtenerCatalogItems(6);
  }
  onMount(selectDietPlan);

  //cargar catalogo que tiene receta
  const CargarDietPlant = async () => {
    try {
      console.log(selectedRecipe);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/recipe-diet-plan/${selectedRecipe.id}`,
        { headers }
      );
      idRecetaCatalog = selectedRecipe.id;
      selectedRecipe = response.data.data;
      console.log(selectedRecipe);
    } catch (err) {
      console.error(err);
    }
  };

  // agregar nuevo
  async function agregarDietPlan() {
    const dietPlanSelect = document.getElementById("dietPlan-select");
    const dietPlanId = dietPlanSelect.value;

    // Verificar si la utensil ya está agregada
    if (!selectedRecipe.find((dietPlan) => dietPlan.id === dietPlanId)) {
      console.log(dietPlanId);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Configurar el objeto de datos a enviar en la solicitud POST
        const data = [
          {
            idRecipe: idRecetaCatalog,
            idDietPlan: dietPlanId,
          },
        ];

        // Realizar la solicitud POST
        const response = await axios.post(
          "https://sandbox.honey-dates.com/api/v1/recipe-diet-plan",
          data,
          { headers }
        );

        console.log(response);
        // Verificar si la solicitud se realizó con éxito
        if (response.status === 200) {
          selectedRecipe = { id: idRecetaCatalog };
          CargarDietPlant();
        } else {
          console.error("Error al agregar la alergia");
        }
      } catch (error) {
        console.error("Error al agregar la alergia:", error);
      }
    }
  }

  // eliminar
  async function eliminarDietPlan(id) {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Realizar la solicitud DELETE con el token de autorización en los encabezados
      const response = await axios.delete(
        `https://sandbox.honey-dates.com/api/v1/recipe-diet-plan/${idRecetaCatalog}/${id}`,
        headers
      );
      // Verificar si la solicitud se realizó con éxito
      if (response.status === 200) {
        selectedRecipe = { id: idRecetaCatalog };
        CargarDietPlant();
      } else {
        console.error("Error al eliminar");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  }

  // ingredientes

  function handleSearch(event) {
    searchValue = event.target.value;
    loadIngredients(); // Vuelve a cargar los ingredientes con el nuevo valor de búsqueda
  }

  // Cargar ingredientes
  const loadIngredients = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/ingredients?page=1&size=5&isDislike=false&val=${searchValue}`,
        { headers }
      );
      maxPage = response.data.data.last;
      ingredients = response.data.data.content;

      if (ingredients.defaultMeasurement === null) {
        ingredients.defaultMeasurement.id = "";
      }

      console.log(ingredients);
    } catch (error) {
      console.error("Error:", error.message);
      ingredients = null;
    }
  };

  const CargarIngredientes = async () => {
    try {
      console.log(selectedRecipe);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/recipe-ingredient/${selectedRecipe.id}`,
        { headers }
      );
      idRecetaCatalog = selectedRecipe.id;
      selectedRecipe = response;
      console.log(selectedRecipe);
    } catch (err) {
      console.error(err);
    }
  };
  
  const AgregarIngrediente = async () => {



  };

  // Posicion de platos
  // cargar al modal
  let allPosicion;
  async function selectPosicion() {
    allPosicion = await obtenerCatalogItems(10);
  }
  onMount(selectPosicion);

  //cargar  catalogo que tiene receta
  const CargarPosicion = async () => {
    try {
      console.log(selectedRecipe);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/recipe-plate/recipe/${selectedRecipe.id}`,
        { headers }
      );
      idRecetaCatalog = selectedRecipe.id;
      selectedRecipe = response.data.data;
      console.log(selectedRecipe);
    } catch (err) {
      console.error(err);
    }
  };

  // agregar nuevo
  async function agregarPosicion() {
    const posicionFoodSelect = document.getElementById("posicion-select");
    const timeFoodId = posicionFoodSelect.value;

    let desc = "";
    let unidad = "";
    let id = 0;

    if (timeFoodId == 1) {
      desc = "Plato Grande";
      unidad = "1/4";
      id = 105;
    } else if (timeFoodId == 2) {
      desc = "Plato Grande";
      unidad = "3/4";
      id = 105;
    } else if (timeFoodId == 3) {
      desc = "Plato Grande";
      unidad = "1/4";
      id = 105;
    } else if (timeFoodId == 4) {
      desc = "Plato Grande";
      unidad = "1/1";
      id = 105;
    } else if (timeFoodId == 5) {
      desc = "PPQ";
      unidad = "1/1";
      id = 106;
    } else if (timeFoodId == 6) {
      desc = "PB";
      unidad = "1/1";
      id = 107;
    }

    // Verificar si la utensil ya está agregada
    if (!selectedRecipe.find((timeFood) => timeFood.id === timeFoodId)) {
      console.log(timeFoodId);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Configurar el objeto de datos a enviar en la solicitud POST
        const data = {
          description: desc,
          idPlateArrangement: id,
          idRecipe: idRecetaCatalog,
          unit: unidad,
        };
        // Realizar la solicitud POST
        const response = await axios.post(
          "https://sandbox.honey-dates.com/api/v1/recipe-plate",
          data,
          { headers }
        );

        console.log(response, data);
        // Verificar si la solicitud se realizó con éxito
        if (response.status === 200) {
          selectedRecipe = { id: idRecetaCatalog };
          CargarPosicion();
        } else {
          console.error("Error al agregar");
        }
      } catch (error) {
        console.error("Error al agregar", error);
        console.log(data);
      }
    }
  }

  // eliminar
  async function eliminarPosicion(id) {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Realizar la solicitud DELETE con el token de autorización en los encabezados
      const response = await axios.delete(
        `https://sandbox.honey-dates.com/api/v1/recipe-plate/${idRecetaCatalog}/${id}`,
        headers
      );
      // Verificar si la solicitud se realizó con éxito
      if (response.status === 200) {
        selectedRecipe = { id: idRecetaCatalog };
        CargarPosicion();
      } else {
        console.error("Error al eliminar");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  }

  // pasos

  // tiempo de comida

  // cargar al modal
  let allTime;
  async function selectTime() {
    allTime = await obtenerCatalogItems(5);
  }
  onMount(selectTime);

  //cargar  catalogo que tiene receta
  const CargarTimeFood = async () => {
    try {
      console.log(selectedRecipe);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/recipe-time/${selectedRecipe.id}`,
        { headers }
      );
      idRecetaCatalog = selectedRecipe.id;
      selectedRecipe = response.data.data;
      console.log(selectedRecipe);
    } catch (err) {
      console.error(err);
    }
  };

  // agregar nuevo
  async function agregarTimeFood() {
    const timeFoodSelect = document.getElementById("time-select");
    const timeFoodId = timeFoodSelect.value;
    // const timeFoodName =
    //   timeFoodSelect.options[timeFoodSelect.selectedIndex].text;

    // Verificar si la utensil ya está agregada
    if (!selectedRecipe.find((timeFood) => timeFood.id === timeFoodId)) {
      console.log(timeFoodId);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Configurar el objeto de datos a enviar en la solicitud POST
        const data = [
          {
            idRecipe: idRecetaCatalog,
            idTimeRecipe: timeFoodId,
          },
        ];
        console.log(data);

        // Realizar la solicitud POST
        const response = await axios.post(
          "https://sandbox.honey-dates.com/api/v1/recipe-time",
          data,
          { headers }
        );

        console.log(response);
        // Verificar si la solicitud se realizó con éxito
        if (response.status === 200) {
          selectedRecipe = { id: idRecetaCatalog };
          CargarTimeFood();
        } else {
          console.error("Error al agregar la alergia");
        }
      } catch (error) {
        console.error("Error al agregar la alergia:", error);
      }
    }
  }

  // eliminar
  async function eliminarTimeFood(id) {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Realizar la solicitud DELETE con el token de autorización en los encabezados
      const response = await axios.delete(
        `https://sandbox.honey-dates.com/api/v1/recipe-time/${idRecetaCatalog}/${id}`,
        headers
      );
      // Verificar si la solicitud se realizó con éxito
      if (response.status === 200) {
        selectedRecipe = { id: idRecetaCatalog };
        CargarTimeFood();
      } else {
        console.error("Error al eliminar");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  }

  // tipo de receta

  // cargar al modal
  let allTypes;
  async function selectTypes() {
    allTypes = await obtenerCatalogItems(7);
    console.log(allTypes);
  }
  onMount(selectTypes);

  //cargar  catalogo que tiene receta
  const CargarTypes = async () => {
    try {
      console.log(selectedRecipe);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/recipe-type/${selectedRecipe.id}`,
        { headers }
      );
      idRecetaCatalog = selectedRecipe.id;
      selectedRecipe = response.data.data;
      console.log(selectedRecipe);
    } catch (err) {
      console.error(err);
    }
  };

  // agregar nuevo
  async function agregarTypes() {
    const typesFoodSelect = document.getElementById("type-select");
    const typesId = typesFoodSelect.value;
    const timeFoodName =
      typesFoodSelect.options[typesFoodSelect.selectedIndex].text;

    // Verificar si la utensil ya está agregada
    if (!selectedRecipe.find((typeFood) => typeFood.id === typesId)) {
      console.log(typesId);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Configurar el objeto de datos a enviar en la solicitud POST
        const data = [
          {
            description: timeFoodName,
            idRecipe: idRecetaCatalog,
            idType: typesId,
          },
        ];

        // Realizar la solicitud POST
        const response = await axios.post(
          "https://sandbox.honey-dates.com​/api/v1/recipe-type",
          data,
          { headers }
        );

        console.log(response);
        // Verificar si la solicitud se realizó con éxito
        if (response.status === 200) {
          selectedRecipe = { id: idRecetaCatalog };
          CargarTypes();
        } else {
          console.error("Error al agregar la alergia");
        }
      } catch (error) {
        console.error("Error al agregar la alergia:", error);
      }
    }
  }

  // eliminar
  async function eliminarTypes(id) {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Realizar la solicitud DELETE con el token de autorización en los encabezados
      const response = await axios.delete(
        `https://sandbox.honey-dates.com/api/v1/recipe-type/${idRecetaCatalog}/${id}`,
        headers
      );
      // Verificar si la solicitud se realizó con éxito
      if (response.status === 200) {
        selectedRecipe = { id: idRecetaCatalog };
        CargarTypes();
      } else {
        console.error("Error al eliminar");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  }

  // utensilios
  // cargar utensilios para el select
  let allUtensils;
  async function selectUtensils() {
    allUtensils = await obtenerCatalogItems(1);
  }
  onMount(selectUtensils);

  //cargar utensilios que tiene receta
  const utensilioDeReceta = async () => {
    try {
      console.log(selectedRecipe);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/recipe-utensils/${selectedRecipe.id}`,
        { headers }
      );
      idRecetaCatalog = selectedRecipe.id;
      selectedRecipe = response.data.data;
    } catch (err) {
      console.error(err);
    }
  };

  // agregar nuevo
  async function agregarUtensils() {
    const utensilSelect = document.getElementById("utensil-select");
    const utensilId = utensilSelect.value;

    // Verificar si la utensil ya está agregada
    if (!selectedRecipe.find((utensil) => utensil.id === utensilId)) {
      console.log(utensilId);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        // Configurar el objeto de datos a enviar en la solicitud POST
        const data = {
          idRecipe: idRecetaCatalog,
          idUtensils: [utensilId],
        };

        // Realizar la solicitud POST
        const response = await axios.post(
          "https://sandbox.honey-dates.com/api/v1/recipe-utensils/add-utensil",
          data,
          { headers }
        );

        console.log(response);
        // Verificar si la solicitud se realizó con éxito
        if (response.status === 200) {
          selectedRecipe = { id: idRecetaCatalog };
          utensilioDeReceta();
        } else {
          console.error("Error al agregar la alergia");
        }
      } catch (error) {
        console.error("Error al agregar la alergia:", error);
      }
    }
  }

  // eliminar
  async function eliminarUtensil(id) {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };

      // Realizar la solicitud DELETE con el token de autorización en los encabezados
      const response = await axios.delete(
        `https://sandbox.honey-dates.com/api/v1/recipe-utensils/delete-utensil`,
        {
          headers,
          data: {
            idRecipe: idRecetaCatalog,
            idUtensils: [id],
          },
        }
      );
      // Verificar si la solicitud se realizó con éxito
      if (response.status === 200) {
        selectedRecipe = { id: idRecetaCatalog };
        utensilioDeReceta();
      } else {
        console.error("Error al eliminar");
      }
    } catch (error) {
      console.error("Error al eliminar:", error);
    }
  }

  // categorias sub categorias de receta

  onMount(() => {
    loadRecipes();
  });
</script>

<div class="container mx-auto px-4">
  <input
    type="text"
    placeholder="Buscar recetas"
    on:input={searchRecipe}
    bind:value={searchValue}
    class="my-4 p-2 rounded-md focus:outline-none focus:border-blue-500"
    style="min-width: 300px ; border: 3px solid rgb(137, 118, 106);"
  />

  <div>
    {#if filteredRecipes}
      {#each filteredRecipes as recipe (recipe.id)}
        <div
          class="border rounded-lg overflow-auto shadow-2xl my-2 flex flex-col items-center bg-blueGray-200 border border-blueGray-300"
        >
          <!-- Contenido de la receta -->
          <div
            style="width: 100%;"
            class="flex items-center justify-around overflow-auto"
          >
            <div style="max-width: 300px;" class="p-4">
              <h3 class="text-lg font-bold mb-2 text-brown-500">
                {recipe.name}
              </h3>
              <p class="text-gray-600">
                Tiempo de preparación: {recipe.minutesPreparation} minutos
              </p>
              <p class="text-gray-600">Code: {recipe.code}</p>
              <p class="text-gray-600">Dificultad: {recipe.idDifficultLevel}</p>
              <p class="text-gray-600">
                Unidad: {recipe.recipePlateArrangements.length > 0
                  ? recipe.recipePlateArrangements[0].unit
                  : "sin unidad de medida"}
              </p>
              <div style="margin-right: 20px;">
                <h3 class="font-bold">Tiempos de Comida:</h3>
                {#each recipe.times as time (time.id)}
                  <p>{time.name}</p>
                {/each}
              </div>
            </div>

            <!-- Imagen de la receta -->
            <img
              src={recipe.imageCoverUrl}
              alt={recipe.name}
              style="object-fit: contain; max-height: 200px; border-radius: 50px;"
              class="p-3"
            />
          </div>
          <div class="flex flex-wrap m-4 gap-6 justify-around">
            <button
              on:click={() => {
                selectedRecipe = { ...recipe };
                ModalContent = 1; // Asignar primero ModalContent
                recipeModal(); // Llamar a recipeModal después
              }}
              class="px-6 py-2 bg-lightBlue-500 py-2 rounded-lg font-bold text-white"
              >Editar</button
            >
            <button
              on:click={() => {
                ModalContent = 2;
                selectedRecipe = { ...recipe };
              }}
              class="px-6 py-2 bg-red-500 py-2 rounded-lg font-bold text-white"
              >Eliminar</button
            >
            <button
              on:click={() => {
                ModalContent = 0;
                selectedRecipe = { ...recipe };
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Ver video
            </button>
            <button
              on:click={() => {
                ModalContent = 3;
                selectedRecipe = { ...recipe };
                CargarDietPlant();
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Plan de Dieta
            </button>
            <button
              on:click={() => {
                ModalContent = 4;
                selectedRecipe = { ...recipe };
                CargarIngredientes();
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Ingredientes
            </button>
            <button
              on:click={() => {
                ModalContent = 5;
                selectedRecipe = { ...recipe };
                CargarPosicion();
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Posición de platos
            </button>
            <button
              on:click={() => {
                ModalContent = 6;
                selectedRecipe = { ...recipe };
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Pasos
            </button>
            <button
              on:click={() => {
                ModalContent = 7;
                selectedRecipe = { ...recipe };
                CargarTimeFood();
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Tiempos de comida
            </button>
            <button
              on:click={() => {
                ModalContent = 8;
                selectedRecipe = { ...recipe };
                CargarTypes();
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Tipo de receta
            </button>
            <button
              on:click={() => {
                ModalContent = 9;
                selectedRecipe = { ...recipe };
                utensilioDeReceta();
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Utensilios
            </button>
            <button
              on:click={() => {
                ModalContent = 10;
                selectedRecipe = { ...recipe };
              }}
              class="text-white font-bold hover:underline py-2 px-2 rounded-lg bg-brown-500"
            >
              Categorias sub categorias de receta
            </button>
          </div>
        </div>
      {/each}
      <!-- Paginación -->
      {#if filteredRecipes.length > 0}
        <!-- Paginación -->
        {#if maxPage > 1}
          <div class="flex justify-center gap-6">
            {#if currentPage !== 1}
              <button
                class="px-6 py-2 bg-brown-500 text-white font-bold m-2 rounded-lg"
                on:click={previousPage}
              >
                Anterior
              </button>
            {/if}
            {#if maxPage !== currentPage}
              <button
                class="px-6 py-2 bg-brown-500 text-white font-bold m-2 rounded-lg"
                on:click={nextPage}
              >
                Siguiente
              </button>
            {/if}
          </div>
        {/if}
      {/if}
    {/if}
  </div>
</div>
<!-- Modal para mostrar el video -->
{#if selectedRecipe}
  <Modal on:close={() => (selectedRecipe = null)}>
    {#if ModalContent === 0}
      <!-- Video de la receta -->
      <!-- svelte-ignore a11y-media-has-caption -->
      <video controls width="500px" height="auto">
        <source src={selectedRecipe.videoUrl} type="video/mp4" />
        Tu navegador no soporta la reproducción de videos.
      </video>
    {:else if ModalContent === 1}
      <!-- Formulario para editar la receta -->
      <form style="max-width: 600px; max-height: 800px;" class="py-2">
        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="code">Código:</label>
          <input
            disabled
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            type="text"
            id="code"
            name="code"
            bind:value={formData.code}
          />
        </div>
        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="name">Nombre:</label>
          <input
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            type="text"
            id="name"
            name="name"
            bind:value={formData.name}
          />
        </div>
        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="name">Nombre en ingles:</label>
          <input
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            type="text"
            id="name"
            name="name"
            bind:value={formData.translate[0].value}
          />
        </div>
        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="minutesPreparation"
            >Minutos de preparación:</label
          >
          <select
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            id="minutesPreparation"
            name="minutesPreparation"
            bind:value={formData.minutesPreparation}
          >
            {#each [15, 30, 45] as option}
              <option value={option}>{option}</option>
            {/each}
          </select>
        </div>

        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="difficultLevel"
            >Dificultad:</label
          >
          <select
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            id="difficultLevel"
            name="difficultLevel"
            bind:value={formData.idDifficultLevel}
          >
            {#each dificultades as dificultad}
              <option
                value={dificultad.id}
                selected={formData.idDifficultLevel === dificultad.id}
                >{dificultad.name}</option
              >
            {/each}
          </select>
        </div>

        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="portions">Porciones:</label>
          <input
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            type="number"
            id="portions"
            name="portions"
            bind:value={formData.portions}
          />
        </div>
        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="videoUrl">URL del video:</label
          >
          <input
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            type="text"
            id="videoUrl"
            name="videoUrl"
            bind:value={formData.videoUrl}
          />
        </div>
        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="imageCroppedUrl"
            >URL de la imagen recortada:</label
          >
          <input
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            type="text"
            id="imageCroppedUrl"
            name="imageCroppedUrl"
            bind:value={formData.imageCroppedUrl}
          />
        </div>
        <div class="my-1 flex flex-wrap">
          <label class="my-1 font-semibold" for="imageCoverUrl"
            >URL de la imagen de portada:</label
          >
          <input
            class="rounded-lg w-full"
            style="border: 2px solid #a8714f;"
            type="text"
            id="imageCoverUrl"
            name="imageCoverUrl"
            bind:value={formData.imageCoverUrl}
          />
        </div>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div
          style="width: 50%; cursor: pointer;
         user-select: none;"
          class="text-center mt-2 mx-auto px-4 py-2 rounded-lg bg-brown-500 text-white font-semibold"
          on:click={EnviarRecetaEditadaModal}
        >
          Guardar
        </div>
      </form>
    {:else if ModalContent === 2}
      <div>
        <h2 class="text-xl mb-4 font-bold">
          ¿Estás seguro de que deseas eliminar?
        </h2>
        <h3 class="font-bold">{selectedRecipe.name}</h3>
        <form on:submit|preventDefault={deleteRecipe}>
          <div class="my-4 flex justify-around items-center">
            <button
              class="bg-blueGray-600 text-white font-bold py-2 px-4 rounded-lg"
              style="height: fit-content;"
              type="button"
              on:click={closeModal}
            >
              Cancelar
            </button>
            <button
              class="bg-red-500 text-white font-bold py-2 px-4 rounded-lg"
              style="height: fit-content;"
              type="submit"
            >
              Eliminar
            </button>
          </div>
        </form>
      </div>
    {:else if ModalContent === 3}
      <div style="min-width: 500px;">
        <div class="mb-4 w-full">
          <h3 class="block text-gray-700 font-bold mb-2">Plan de Dieta:</h3>
          <div class="flex gap-4 items-center">
            <select
              id="dietPlan-select"
              class="py-2 px-6 border rounded-md mr-2"
            >
              {#each allDietPlan as DietPlan}
                <option class="px-2" value={DietPlan.id}>{DietPlan.name}</option
                >
              {/each}
            </select>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              on:click={agregarDietPlan}
              class="p-2 bg-brown-500 text-white font-bold rounded-md cursor-pointer"
            >
              Agregar
            </div>
          </div>
        </div>
        {#if selectedRecipe.length > 0}
          {#each selectedRecipe as DietPlanilioQueTieneReceta}
            <div class="flex items-center">
              <h3>{DietPlanilioQueTieneReceta.description}</h3>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                on:click={() =>
                  eliminarDietPlan(DietPlanilioQueTieneReceta.idDietPlan)}
                class="text-red-500 font-bold cursor-pointer ml-2"
              >
                X
              </div>
            </div>
          {/each}
        {:else}
          <p>No tiene alergias.</p>
        {/if}
      </div>
    {:else if ModalContent === 4}
      <div style="min-width: 500px;">
        <div class="mb-4">
          <label for="searchInput" class="block text-gray-700 font-bold mb-2">
            Buscar ingrediente:
          </label>
          <input
            type="text"
            id="searchInput"
            class="p-2 border rounded-lg"
            bind:value={searchValue}
            on:input={handleSearch}
          />
        </div>
        <!-- Renderizado de ingredientes -->
        {#if ingredients !== null && ingredients.length > 0}
          {#each ingredients as ingredient (ingredient.id)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <!-- svelte-ignore a11y-interactive-supports-focus -->
            <div
              on:click={(ingredientSelected = ingredient), AgregarIngrediente}
              style="max-width: max-content; cursor: pointer;"
              class="border rounded-lg overflow-hidden shadow-2xl m-2 flex flex-col bg-blueGray-200 border border-blueGray-300"
              role="button"
              aria-label="Seleccionar ingrediente: {ingredient.name}"
            >
              <!-- Nombre del ingrediente -->
              <h6 class="text-blueGray-800">
                {ingredient.name}
              </h6>
            </div>
          {/each}
        {/if}
      </div>
    {:else if ModalContent === 5}
      <div style="min-width: 500px;">
        <div class="mb-4 w-full">
          <h3 class="block text-gray-700 font-bold mb-2">
            Posición de Platos:
          </h3>
          <div class="flex gap-4 items-center">
            <select
              id="posicion-select"
              class="py-2 px-6 border rounded-md mr-2"
            >
              <option value="1">Plato Grande 1/4</option>
              <option value="2">Plato Grande 3/4</option>
              <option value="3">Plato Grande 1/2</option>
              <option value="4">Plato Grande 1/1</option>
              <option value="5">PPQ 1/1</option>
              <option value="6">PB 1/1</option>
            </select>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              on:click={agregarPosicion}
              class="p-2 bg-brown-500 text-white font-bold rounded-md cursor-pointer"
            >
              Agregar
            </div>
          </div>
        </div>
        {#if selectedRecipe.length > 0}
          {#each selectedRecipe as PosicionQueTieneReceta}
            <div class="flex items-center">
              <h3>{PosicionQueTieneReceta.description}</h3>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                on:click={() =>
                  eliminarPosicion(PosicionQueTieneReceta.idPlateArrangement)}
                class="text-red-500 font-bold cursor-pointer ml-2"
              >
                X
              </div>
            </div>
          {/each}
        {:else}
          <p>No tiene Posición de platos.</p>
        {/if}
      </div>
    {:else if ModalContent === 6}
      <div style="min-width: 500px;">6</div>
    {:else if ModalContent === 7}
      <div style="min-width: 500px;">
        <div class="mb-4 w-full">
          <h3 class="block text-gray-700 font-bold mb-2">Tiempos de Comida:</h3>
          <div class="flex gap-4 items-center">
            <select id="time-select" class="py-2 px-6 border rounded-md mr-2">
              {#each allTime as time}
                <option class="px-2" value={time.id}>{time.name}</option>
              {/each}
            </select>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              on:click={agregarTimeFood}
              class="p-2 bg-brown-500 text-white font-bold rounded-md cursor-pointer"
            >
              Agregar
            </div>
          </div>
        </div>
        {#if selectedRecipe.length > 0}
          {#each selectedRecipe as TiemposQueTieneReceta}
            <div class="flex items-center">
              <h3>{TiemposQueTieneReceta.description}</h3>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                on:click={() =>
                  eliminarTimeFood(TiemposQueTieneReceta.idTimeRecipe)}
                class="text-red-500 font-bold cursor-pointer ml-2"
              >
                X
              </div>
            </div>
          {/each}
        {:else}
          <p>No tiene Tiempos de Comida.</p>
        {/if}
      </div>
    {:else if ModalContent === 8}
      <div style="min-width: 500px;">
        <div class="mb-4 w-full">
          <h3 class="block text-gray-700 font-bold mb-2">Tipos de Comida:</h3>
          <div class="flex gap-4 items-center">
            <select id="type-select" class="py-2 px-6 border rounded-md mr-2">
              {#each allTypes as type}
                <option class="px-2" value={type.id}>{type.name}</option>
              {/each}
            </select>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              on:click={agregarTypes}
              class="p-2 bg-brown-500 text-white font-bold rounded-md cursor-pointer"
            >
              Agregar
            </div>
          </div>
        </div>
        {#if selectedRecipe.length > 0}
          {#each selectedRecipe as TiposQueTieneReceta}
            <div class="flex items-center">
              <h3>{TiposQueTieneReceta.description}</h3>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                on:click={() => eliminarTypes(TiposQueTieneReceta.idType)}
                class="text-red-500 font-bold cursor-pointer ml-2"
              >
                X
              </div>
            </div>
          {/each}
        {:else}
          <p>No tiene Tipos de Receta.</p>
        {/if}
      </div>
    {:else if ModalContent === 9}
      <div style="min-width: 500px;">
        <div class="mb-4 w-full">
          <h3 class="block text-gray-700 font-bold mb-2">Utensilios:</h3>
          <div class="flex gap-4 items-center">
            <select
              id="utensil-select"
              class="py-2 px-6 border rounded-md mr-2"
            >
              {#each allUtensils as utensil}
                <option class="px-2" value={utensil.id}>{utensil.name}</option>
              {/each}
            </select>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
              on:click={agregarUtensils}
              class="p-2 bg-brown-500 text-white font-bold rounded-md cursor-pointer"
            >
              Agregar
            </div>
          </div>
        </div>
        {#if selectedRecipe.length > 0}
          {#each selectedRecipe as utensilioQueTieneReceta}
            <div class="flex items-center">
              <h3>{utensilioQueTieneReceta.utensilName}</h3>
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <div
                on:click={() =>
                  eliminarUtensil(utensilioQueTieneReceta.idUtensil)}
                class="text-red-500 font-bold cursor-pointer ml-2"
              >
                X
              </div>
            </div>
          {/each}
        {:else}
          <p>No tiene alergias.</p>
        {/if}
      </div>
    {:else if ModalContent === 10}
      <div style="min-width: 500px;">10</div>
    {/if}
  </Modal>
{/if}
