<!-- App.svelte -->
<script>
  import { onMount } from 'svelte';
  import { Router, Route } from "svelte-routing";
  import axios from 'axios';

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";
  // No Layout Pages
  import Index from "./views/Index.svelte";

  export let url = "";
  
  onMount(() => {
    // Verificar si ya hay un token de sesión en sessionStorage
    const token = sessionStorage.getItem('token');

    // Obtén la ruta actual
    const currentPath = window.location.pathname;

    if (token) {
      // Si hay un token de sesión presente
      if (!currentPath.startsWith('/admin')) {
        // Si la ruta actual no comienza con '/admin', redirigir al dashboard
        window.location.href = '/admin/dashboard';
      }
    } else {
      // Si no hay token de sesión, redirigir a la página de inicio de sesión
      if (currentPath !== '/auth/login') {
        window.location.href = '/auth/login';
      }
    }
  });
</script>

<Router url="{url}">
  <!-- admin layout -->
  <Route path="admin/*admin" component="{Admin}" />
  <!-- auth layout -->
  <Route path="auth/*auth" component="{Auth}" />
  <!-- no layout pages -->
  <Route path="/" component="{Index}" />
</Router>
