<script>
  import { link } from "svelte-routing";

  // core components
  import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
  import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
  import DropdownCatalogs from "components/Dropdowns/PagesDropdown.svelte";

  let collapseShow = "hidden";
  let enlaceNum = 1;

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  function enlaceActive(num) {
    enlaceNum = num;
  }
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 pl-4"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <!-- Toggler -->
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a
      use:link
      class="md:block text-left md:pb-2 mr-0 inline-block  p-4 px-0"
      href="/admin/dashboard"
    >
    <img style="max-width: 200px!important; margin: auto;" src="../assets/img/logo.svg" alt="">
    </a>
    <!-- User -->
    <ul class="md:hidden items-center flex flex-wrap list-none">
      <li class="inline-block relative">
        <NotificationDropdown />
      </li>
      <li class="inline-block relative">
        <UserDropdown />
      </li>
    </ul>
    <!-- Collapse -->
    <div
     style="max-width: 20rem!important;"
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto w-64 overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <!-- Collapse header -->
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              use:link
              class="md:block text-left md:pb-2 mr-0 inline-block  p-4 px-0"
              href="/admin/dashboard"
            >
            <img style="max-width: 200px!important; margin: auto;" src="../assets/img/logo.svg" alt="">
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow('hidden')}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />

      <!-- Navigation -->
  
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <li class="items-center" on:click={() => enlaceActive(1)}>
          <a
            use:link
            href="/admin/dashboard"
            class="text-xs uppercase py-3 font-bold block  {enlaceNum == 1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm  {enlaceNum == 1 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Dashboard
          </a>
        </li>
      </ul>

      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Carga de Datos
      </h6>
      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <li class="items-center" on:click={() => enlaceActive(2)}>
          <a
            use:link
            href="/admin/recetas"
            class="text-xs uppercase py-3 font-bold block  {enlaceNum == 2 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm  {enlaceNum == 2 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Recetas
          </a>
        </li>
<!-- svelte-ignore a11y-click-events-have-key-events -->
        <li class="items-center" on:click={() => enlaceActive(3)}>
          <a
            use:link
            href="/admin/ingredientes"
            class="text-xs uppercase py-3 font-bold block  {enlaceNum == 3 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm  {enlaceNum == 3 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Ingredientes
          </a>
        </li>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <li class="items-center" on:click={() => enlaceActive(4)}>
          <a
            use:link
            href="/admin/archivos"
            class="text-xs uppercase py-3 font-bold block  {enlaceNum == 4 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tools mr-2 text-sm  {enlaceNum == 4 ? 'opacity-75' : 'text-blueGray-300'}"
            ></i>
            Multimedia
          </a>
        </li>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
<li class="items-center" on:click={() => enlaceActive(5)}>

    <DropdownCatalogs />

</li>

      </ul>
      <!-- Divider -->
      <hr class="my-4 md:min-w-full" />
      <!-- Heading -->
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Documentation
      </h6>
      <!-- Navigation -->
      <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
        <li class="inline-flex">
          <a
            href="https://sandbox.honey-dates.com/api/v1/swagger-ui/index.html#/"
            target="_blank"
            class="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
          >
            <i class="fas fa-paint-brush mr-2 text-blueGray-300 text-base"></i>
            Swagger
          </a>
        </li>

        <!-- <li class="inline-flex">
          <a
            href="https://www.creative-tim.com/learning-lab/tailwind/svelte/colors/notus"
            target="_blank"
            class="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
          >
            <i class="fas fa-paint-brush mr-2 text-blueGray-300 text-base"></i>
            Styles
          </a>
        </li>

        <li class="inline-flex">
          <a
            href="https://www.creative-tim.com/learning-lab/tailwind/svelte/alerts/notus"
            target="_blank"
            class="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
          >
            <i class="fab fa-css3-alt mr-2 text-blueGray-300 text-base"></i>
            CSS Components
          </a>
        </li>

        <li class="inline-flex">
          <a
            href="https://www.creative-tim.com/learning-lab/tailwind/js/overview/notus"
            target="_blank"
            class="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
          >
            <i class="fab fa-js-square mr-2 text-blueGray-300 text-base"></i>
            Javascript
          </a>
        </li>

        <li class="inline-flex">
          <a
            href="https://www.creative-tim.com/learning-lab/tailwind/svelte/overview/notus"
            target="_blank"
            class="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
          >
            <i class="fas fa-link mr-2 text-blueGray-300 text-base"></i>
            Svelte
          </a>
        </li> -->

      </ul>
    </div>
  </div>
</nav>
