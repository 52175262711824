<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-routing";

  let user = "";
  let password = "";
  let error = "";
  let showPassword = false;

  const fakeLogin = async () => {
    const fakeToken = "fakeToken123";

    if (user === "admin" && password === "sandra123") {
      sessionStorage.setItem('token', fakeToken); 
      navigate("/admin/dashboard");
    } else {
      error = "Usuario o Contraseña incorrectos";
    }
  };

  onMount(() => {
    const token = sessionStorage.getItem('token');

    if (token) {
      navigate("/admin/dashboard");
    }
  });
</script>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
        <div class="flex-auto px-4 lg:px-10 py-10">
          <form>
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="user">Usuario</label>
              <input
                bind:value={user}
                type="text"
                class="border-0 px-3 py-3 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Usuario"
              />
            </div>

            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for="password">Contraseña</label>
              <div class="relative">
                <input
                  value={password}
                  type={showPassword ? "text" : "password"}
                  class="border-0 px-3 py-3 pr-10 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Contraseña"
                  on:input={(event) => password = event.target.value}
                />
                <span class="absolute inset-y-0 right-0 pr-3 flex items-center text-blueGray-600">
                  <button
                    type="button" 
                    class="p-1 focus:outline-none focus:ring"
                    on:click={() => showPassword = !showPassword}
                  >
                    <i class="{showPassword ? 'far fa-eye-slash' : 'far fa-eye'}"></i>
                  </button>
                </span>
              </div>
            </div>
            
            {#if error}
              <div class="text-red-500 mb-3">{error}</div>
            {/if}

            <div class="text-center mt-6">
              <button
                type="button"
                on:click={fakeLogin}
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
              >
                Ingresar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
