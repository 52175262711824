<script>
  import { onMount } from "svelte";
  import axios from "axios";

  // cargar modal
  import ModalArchivos from "components/ModalArchivos.svelte";
  import Multimedia from "views/admin/Archivos.svelte";
  let ModalArchivosNum = 0;
  function ModalContentOpen() {
    ModalArchivosNum = 1;
  }
  function closeModalArchivos() {
    ModalArchivosNum = 0;
  }

  // Global variables
  const token =
    "eyJhbGciOiJIUzUxMiJ9.eyJlbWFpbE9yUGhvbmUiOiJjYWxkZXJvbi5qb3JnZTRAZ21haWwuY29tIiwiaWQiOjM1NCwibmFtZSI6IkpvcmdlIENhbGRlcm9uIiwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInN1YiI6IjM1NCIsImlhdCI6MTcwOTAxMTgwMCwiZXhwIjoxNzQwNTQ3ODAwfQ.W1-QoXZmtxsNWvDQ29tJPl_mRVvdxg_H1DXpPWlBSY2oymdZORf9sPFtUMgT1Td8m2gXOUXcF1A3t-TwAlHZXw";
  let currentStep = 1; // Variable para rastrear el paso actual
  const totalSteps = 11; // Número total de pasos
  // Variable para buscar ingredientes
  const obtenerCatalogItems = async (idCatalog) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };
      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/catalog/type/${idCatalog}`,
        { headers }
      );

      return response.data.data;
    } catch (error) {
      console.error("Error al obtener los ítems del catálogo:", error);
    }
  };

  // Variable para almacenar los ingredientes seleccionados
  let recetaMain = {
    Code: "",
    idDifficultLevel: 0,
    minutesPreparation: 0,
    Portions: 0,
    Name: "",
    translateSave: [
      { column: "name", language: "en", value: "" },
      { column: "videoUrl", language: "en", value: "" },
    ],
    plateArrangement: [{ idPlateArrangement: 0, unit: "" }],
    imageCoverUrl: "",
    imageCroppedUrl: "",
    videoUrl: "",
    ingredients: [],
    components: [],
    utensils: [],
    types: [],
    timesFood: [],
    dietPlans: [],
    categorySubcategories: [],
    steps: [],
  };
  if (recetaMain.plateArrangement[0].idPlateArrangement == "105") {
    recetaMain.plateArrangement[0].unit = "Plato Grande";
  } else if (recetaMain.plateArrangement[0].idPlateArrangement == "106") {
    recetaMain.plateArrangement[0].unit = "Plato Pequeño";
  } else if (recetaMain.plateArrangement[0].idPlateArrangement == "107") {
    recetaMain.plateArrangement[0].unit = "Plato Bowl";
  }

  // Función para avanzar al siguiente paso
  const nextStep = () => {
    if (currentStep < totalSteps) {
      currentStep++;
    }
  };

  // Función para retroceder al paso anterior
  const prevStep = () => {
    if (currentStep > 1) {
      currentStep--;
    }
  };

  // Paso 3 script
  let searchValueIng = "";
  let Vacio = "";
  let ingredients = [];
  let dropdownOpenIng = false;
  let selectIngredients;

  // Función para buscar ingredientes
  const searchIngredient = async () => {
    selectIngredients = await obtenerCatalogItems(12);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/ingredients?page=1&size=10&isDislike=false&val=${searchValueIng}`,
        { headers }
      );

      ingredients = response.data.data.content;
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // Función para agregar un ingrediente seleccionado
  const addSelectedIngredient = (ingredient) => {
    const newIngredient = {
      name: ingredient.name,
      idIngredient: ingredient.id,
      quantity: 0, // Valor por defecto, se actualizará con el input
      idCutType: 0, // Valor por defecto, se actualizará con el input
      especificationIngredient: "", // Valor por defecto, se actualizará con el input
      translateSave: [
        {
          column: "especification_ingredient",
          languague: "en",
          value: "",
        },
      ],
    };
    recetaMain.ingredients = [...recetaMain.ingredients, newIngredient];
    dropdownOpenIng = false; // Cerrar el dropdown después de seleccionar una receta
    searchValueIng = "";
    console.log(recetaMain.ingredients);
  };

  // Función para remover un ingrediente seleccionado
  const removeSelectedIngredient = (ingredient) => {
    recetaMain.ingredients = recetaMain.ingredients.filter(
      (r) => r !== ingredient
    );
  };

  // Función para cerrar el dropdown al perder el foco
  const closeDropdown = () => {
    setTimeout(() => {
      dropdownOpenIng = false;
    }, 500); // Retraso para asegurar que el evento click en la receta se maneje antes de cerrar el dropdown
  };

  // Función para abrir el dropdown cuando se enfoca el input
  const openDropdown = () => {
    dropdownOpenIng = true;
  };

  // Paso 4 script
  let searchValueIngComponent = "";
  let ingredientsComponent = [];
  let resultadosFiltrados = [];
  let dropdownOpenIngComponent = false;
  let selectComponentsTypeCute;

  // Función para buscar ingredientes en el Paso 4
  let selectedIngredients = [];
  const searchIngredientComponent = async () => {
    selectComponentsTypeCute = await obtenerCatalogItems(12);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Accept-Language": "es",
      };

      const response = await axios.get(
        `https://sandbox.honey-dates.com/api/v1/ingredients?page=1&size=10&isDislike=false&val=${searchValueIngComponent}`,
        { headers }
      );
      dropdownOpenIngComponent = response.data.data.content;
      ingredientsComponent = response.data.data.content;
      console.log(ingredientsComponent);
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // Función para agregar un ingrediente seleccionado en el Paso 4
  const addSelectedIngredientComponent = (ingredient) => {
    const newComponent = {
      name: ingredient.name,
      idIngredient: ingredient.id,
      idComponent: 0, // Actualiza el ID del componente
      quantity: 0,
      idCutType: 0,
      especificationIngredient: "",
      translateSave: [
        {
          column: "especification_ingredient",
          languague: "en",
          value: "",
        },
      ],
    };
    recetaMain.components = [...recetaMain.components, newComponent]; // Agrega el ingrediente a la lista
    dropdownOpenIngComponent = false; // Cierra el dropdown después de seleccionar un ingrediente
    searchValueIngComponent = "";
    console.log(recetaMain.components);
  };

  // Función para remover un ingrediente seleccionado en el Paso 4
  const removeSelectedIngredientComponent = (ingredient) => {
    recetaMain.components = recetaMain.components.filter(
      (r) => r !== ingredient
    );
  };

  // Función para cerrar el dropdown al perder el foco en el Paso 4
  const closeDropdownComponent = () => {
    setTimeout(() => {
      dropdownOpenIngComponent = false;
    }, 500);
  };

  // Función para abrir el dropdown cuando se enfoca el input en el Paso 4
  const openDropdownComponent = () => {
    dropdownOpenIngComponent = true;
  };

  // Función para sacar los componentes de receta
  let selectComponentsList;
  async function selectComponents() {
    selectComponentsList = await obtenerCatalogItems(14);
    console.log(selectComponentsList);
  }
  onMount(selectComponents); // No necesitas usar los paréntesis dentro de onMount

  // Paso 5
  let searchValueUtensils = ""; // Variable para almacenar el valor de búsqueda de utensilios
  let utensils = []; // Variable para almacenar los utensilios obtenidos
  let dropdownOpenUtensils = false; // Variable para controlar la visibilidad del dropdown de utensilios
  let selectedUtensils = []; // Variable para almacenar los utensilios seleccionados

  // Función para obtener los utensilios del catálogo
  let selectUtensilsList;
  async function selectUtensils() {
    selectUtensilsList = await obtenerCatalogItems(1); // Obtener los utensilios del catálogo
  }
  onMount(selectUtensils); // Llamar a la función al montar el componente

  // Función para buscar utensilios
  const searchUtensils = async () => {
    await selectUtensils(); // Esperar a que se completen los utensilios
    utensils = selectUtensilsList.filter((utensil) =>
      utensil.name.toLowerCase().includes(searchValueUtensils.toLowerCase())
    );
    dropdownOpenUtensils = utensils.length > 0; // Actualizar el estado del dropdown
    console.log(dropdownOpenUtensils); // Verificar el valor de dropdownOpenUtensils
  };

  // Función para agregar un utensilio seleccionado
  const addSelectedUtensil = (utensil) => {
    const newUtensil = {
      name: utensil.name,
      idUtensil: utensil.id,
    };
    selectedUtensils = [...selectedUtensils, newUtensil]; // Actualiza la lista de utensilios seleccionados
    recetaMain.utensils = [...recetaMain.utensils, { id: utensil.id }]; // Agrega el objeto con el id al arreglo de utensilios en recetaMain
    utensils = utensils.filter((u) => u !== utensil); // Elimina el utensilio seleccionado de la lista de utensilios disponibles
    dropdownOpenUtensils = false; // Cierra el dropdown después de seleccionar un utensilio
    searchValueUtensils = ""; // Limpiar el valor de búsqueda
    console.log(selectedUtensils, recetaMain); // Para verificar si los utensilios se agregan correctamente
  };

  // Función para remover un utensilio seleccionado
  const removeSelectedUtensil = (utensil) => {
    selectedUtensils = selectedUtensils.filter((u) => u !== utensil);
    recetaMain.utensils = selectedUtensils.map((utensil) => utensil.idUtensil); // Actualiza la lista de utensilios en recetaMain
    console.log(recetaMain);
  };

  // Función para cerrar el dropdown de utensilios al perder el foco
  const closeDropdownUtensils = () => {
    setTimeout(() => {
      dropdownOpenUtensils = false;
    }, 300);
  };

  // Función para abrir el dropdown de utensilios cuando se enfoca el input
  const openDropdownUtensils = () => {
    dropdownOpenUtensils = true;
  };

  // Paso 6
  let searchValueType = "";
  let types = [];
  let dropdownOpenType = false;
  let selectedTypes = [];

  let selectTypesList;
  async function selectTypes() {
    selectTypesList = await obtenerCatalogItems(7);
  }
  onMount(selectTypes);

  const searchTypes = async () => {
    await selectTypes();
    types = selectTypesList.filter((type) =>
      type.name.toLowerCase().includes(searchValueType.toLowerCase())
    );
    dropdownOpenType = types.length > 0;
  };

  const addSelectedType = (type) => {
    const newType = {
      name: type.name,
      idType: type.id,
    };
    selectedTypes = [...selectedTypes, newType];
    recetaMain.types = [...recetaMain.types, { id: type.id }];
    types = types.filter((t) => t !== type);
    dropdownOpenType = false;
    searchValueType = "";
  };

  const removeSelectedType = (type) => {
    selectedTypes = selectedTypes.filter((t) => t !== type);
    recetaMain.types = selectedTypes.map((type) => type.idType);
  };

  const closeDropdownType = () => {
    setTimeout(() => {
      dropdownOpenType = false;
    }, 300);
  };

  const openDropdownType = () => {
    dropdownOpenType = true;
  };

  // Paso 7
  let searchValueTimeFood = "";
  let timesFood = [];
  let dropdownOpenTimeFood = false;
  let selectedTimesFood = [];

  let selectTimesFoodList;
  async function selectTimesFood() {
    selectTimesFoodList = await obtenerCatalogItems(5);
  }
  onMount(selectTimesFood);

  const searchTimesFood = async () => {
    await selectTimesFood();
    timesFood = selectTimesFoodList.filter((time) =>
      time.name.toLowerCase().includes(searchValueTimeFood.toLowerCase())
    );
    dropdownOpenTimeFood = timesFood.length > 0;
  };

  const addSelectedTimeFood = (time) => {
    const newTime = {
      name: time.name,
      idTimeFood: time.id,
    };
    selectedTimesFood = [...selectedTimesFood, newTime];
    recetaMain.timesFood = [...recetaMain.timesFood, { id: time.id }];
    timesFood = timesFood.filter((t) => t !== time);
    dropdownOpenTimeFood = false;
    searchValueTimeFood = "";
  };

  const removeSelectedTimeFood = (time) => {
    selectedTimesFood = selectedTimesFood.filter((t) => t !== time);
    recetaMain.timesFood = selectedTimesFood.map((time) => time.idTimeFood);
  };

  const closeDropdownTimeFood = () => {
    setTimeout(() => {
      dropdownOpenTimeFood = false;
    }, 300);
  };

  const openDropdownTimeFood = () => {
    dropdownOpenTimeFood = true;
  };

  // Paso 8
  let searchValueDietPlan = "";
  let dietPlans = [];
  let dropdownOpenDietPlan = false;
  let selectedDietPlans = [];

  let selectDietPlansList;
  async function selectDietPlans() {
    selectDietPlansList = await obtenerCatalogItems(6);
  }
  onMount(selectDietPlans);

  const searchDietPlans = async () => {
    await selectDietPlans();
    dietPlans = selectDietPlansList.filter((diet) =>
      diet.name.toLowerCase().includes(searchValueDietPlan.toLowerCase())
    );
    dropdownOpenDietPlan = dietPlans.length > 0;
  };

  const addSelectedDietPlan = (diet) => {
    const newDiet = {
      name: diet.name,
      idDietPlan: diet.id,
    };
    selectedDietPlans = [...selectedDietPlans, newDiet];
    recetaMain.dietPlans = [...recetaMain.dietPlans, { id: diet.id }];
    dietPlans = dietPlans.filter((d) => d !== diet);
    dropdownOpenDietPlan = false;
    searchValueDietPlan = "";
  };

  const removeSelectedDietPlan = (diet) => {
    selectedDietPlans = selectedDietPlans.filter((d) => d !== diet);
    recetaMain.dietPlans = selectedDietPlans.map((diet) => diet.idDietPlan);
  };

  const closeDropdownDietPlan = () => {
    setTimeout(() => {
      dropdownOpenDietPlan = false;
    }, 300);
  };

  const openDropdownDietPlan = () => {
    dropdownOpenDietPlan = true;
  };

  // paso 9
  let searchValueCategorySubcategory = "";
  let categorySubcategoriesList = [];
  let dropdownOpenCategorySubcategory = false;
  let selectedCategorySubcategories = [];

  let selectCategorySubcategoriesList;
  async function selectCategorySubcategories() {
    selectCategorySubcategoriesList = await obtenerCatalogItems(17);
  }
  onMount(selectCategorySubcategories);

  const searchCategorySubcategories = async () => {
    await selectCategorySubcategories();
    categorySubcategoriesList = selectCategorySubcategoriesList.filter(
      (category) =>
        category.name
          .toLowerCase()
          .includes(searchValueCategorySubcategory.toLowerCase())
    );
    dropdownOpenCategorySubcategory = categorySubcategoriesList.length > 0;
  };

  const addSelectedCategorySubcategory = (category) => {
    const newCategorySubcategory = {
      name: category.name,
      idCategorySubcategory: category.id,
    };
    selectedCategorySubcategories = [
      ...selectedCategorySubcategories,
      newCategorySubcategory,
    ];
    recetaMain.categorySubcategories = selectedCategorySubcategories.map(
      (category) => category.idCategorySubcategory
    );
    dropdownOpenCategorySubcategory = false;
    searchValueCategorySubcategory = "";
  };

  const removeSelectedCategorySubcategory = (category) => {
    selectedCategorySubcategories = selectedCategorySubcategories.filter(
      (selected) => selected !== category
    );
    recetaMain.categorySubcategories = selectedCategorySubcategories.map(
      (selected) => selected.idCategorySubcategory
    );
  };

  const closeDropdownCategorySubcategory = () => {
    setTimeout(() => {
      dropdownOpenCategorySubcategory = false;
    }, 300);
  };

  const openDropdownCategorySubcategory = () => {
    dropdownOpenCategorySubcategory = true;
  };

  // paso 10
  const addStep = () => {
    recetaMain.steps = [
      ...recetaMain.steps,
      {
        description: "",
        videoUrl: "",
        videoUrlIngles: "",
        translateSave: [
          {
            column: "especification_ingredient",
            language: "en",
            value: "",
          },
        ],
      },
    ];
  };

  const removeStep = (index) => {
    recetaMain.steps = recetaMain.steps.filter((step, i) => i !== index);
  };

  // Paso 11 Enviar
  const enviarFunction = async () => {
    document.getElementById("mensajeEnviar").textContent = "Agregando Receta";
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(
        `https://sandbox.honey-dates.com/api/v1/recipe`,
        recetaMain,
        { headers }
      );
      document.getElementById("mensajeEnviar").textContent =
        "Receta Agregada correctamente";
      setTimeout(() => {    
        window.location.reload();
      }, 3000);

      return response.data;
    } catch (error) {
      document.getElementById("mensajeEnviar").textContent =
        "Hubo un error al agregar la Receta";
      console.error("Error al crear receta:", error);
    }
  };
</script>

<div>
  <button
    class="px-4 py-2 rounded-lg text-white font-semibold bg-red-500"
    on:click={console.log(recetaMain)}
    >Console.log de Receta
  </button>
  <button
    class="px-4 py-2 rounded-lg text-white font-semibold bg-brown-500"
    on:click={ModalContentOpen}>Agregar Multimedia</button
  >
</div>
<div class="max-w-2xl mx-auto my-10">
  <div class="flex items-center justify-center flex-wrap mb-4">
    <!-- Número de paso actual -->
    <div
      style="color: #89766a;"
      class="text-3xl text-lg w-full text-center font-bold"
    >
      {currentStep} de {totalSteps}
    </div>
    <!-- Indicador de progreso -->
    <div
      style="max-width:800px;"
      class="w-full bg-gray-300 h-8 rounded-full overflow-hidden"
    >
      <div
        class="h-full bg-brown-500"
        style="width: calc((100% / 7) * {currentStep});"
      ></div>
    </div>
  </div>

  <!-- Contenido del paso actual -->
  <div style="min-height: 400px;" class="border p-4 rounded-lg">
    <!-- Aquí va el contenido específico de cada paso -->
    {#if currentStep === 1}
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Datos principales de la Receta
      </h1>
      <div class="flex justify-center items-center">
        <div class="bg-blueGray-200 shadow-2xl p-8">
          <h3>Nombre</h3>
          <input
            style="min-width: 400px"
            type="text"
            bind:value={recetaMain.Name}
          />

          <h3>Nombre ingles</h3>
          <input
            style="min-width: 400px"
            type="text"
            bind:value={recetaMain.translateSave[0].value}
          />

          <h3>Código</h3>
          <input
            style="min-width: 400px"
            type="text"
            bind:value={recetaMain.Code}
          />

          <h3>Nivel de Dificultad</h3>
          <select
            style="min-width: 100%;"
            bind:value={recetaMain.idDifficultLevel}
          >
            <option value="91">Fácil</option>
            <option value="92">Intermedio</option>
            <option value="93">Avanzado</option>
          </select>

          <h3>Minutos de preparación</h3>
          <select
            style="min-width: 100%;"
            bind:value={recetaMain.minutesPreparation}
          >
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="45">45</option>
          </select>

          <h3>Porciones</h3>
          <input
            style="min-width: 400px"
            type="number"
            bind:value={recetaMain.Portions}
          />

          <h3>Disposición de los platos</h3>
          <select
            style="min-width: 400px"
            bind:value={recetaMain.plateArrangement[0].idPlateArrangement}
          >
            <option value="105">Plato Grande 1/4</option>
            <option value="105">Plato Grande 3/4</option>
            <option value="105">Plato Grande 1/2</option>
            <option value="105">Plato Grande 1/1</option>
            <option value="106">PPQ 1/1</option>
            <option value="107">PB 1/1</option>
          </select>
        </div>
      </div>
    {/if}
    {#if currentStep === 2}
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Nombre de Archivos Multimedia
      </h1>
      <div class="flex justify-center items-center">
        <div class="bg-blueGray-200 shadow-2xl p-8">
          <h3>Imagen de portada</h3>
          <input type="text" bind:value={recetaMain.imageCoverUrl} />

          <h3>Imagen Recortada</h3>
          <input type="text" bind:value={recetaMain.imageCroppedUrl} />

          <h3>Video URL</h3>
          <input type="text" bind:value={recetaMain.videoUrl} />

          <h3>Video URL en Inglés</h3>
          <input type="text" bind:value={recetaMain.translateSave[1].value} />
        </div>
      </div>
    {/if}
    {#if currentStep === 3}
      <!-- Paso 3 -->
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Ingredientes
      </h1>
      <div>
        <input
          type="text"
          placeholder="Buscar Ingredientes"
          on:input={searchIngredient}
          bind:value={searchValueIng}
          on:blur={closeDropdown}
          on:focus={openDropdown}
        />
        <div>
          {#if dropdownOpenIng && ingredients.length > 0}
            <ul>
              {#each ingredients as ingredient (ingredient.id)}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <li
                  style="cursor: pointer;"
                  on:click={() => addSelectedIngredient(ingredient)}
                >
                  {ingredient.name}
                </li>
              {/each}
            </ul>
          {/if}
        </div>
      </div>

      <!-- Mostrar ingredientes seleccionados -->
      <h2>Ingredientes Seleccionados</h2>
      <div class="flex flex-wrap" style="gap: 15px;">
        {#each recetaMain.ingredients as ingredient (ingredient.idIngredient)}
          <div class="shadow-2xl my-6 bg-blueGray-200 p-4 rounded-lg">
            <div class="flex items-center justify-between">
              {ingredient.name}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <span
                style="cursor: pointer; font-size: 30px; font-weight: 800; margin-left: 15px;"
                on:click={() => removeSelectedIngredient(ingredient)}>✕</span
              >
            </div>
            <!-- Inputs para los campos adicionales -->
            <div>
              <h3>Cantidad de Ingrediente en gramos</h3>
              <input
                class="my-4"
                type="number"
                placeholder="Quantity"
                bind:value={ingredient.quantity}
              /> <br />
            </div>
            <div>
              <h3>ID tipo de corte</h3>
              <select class="mb-2" bind:value={ingredient.idCutType}>
                {#each selectIngredients as option}
                  <option value={option.id}>{option.name}</option>
                {/each}
              </select>
              <br />
            </div>
            <div>
              <h3>Especificación de Ingrediente</h3>
              <input
                class="my-4"
                type="text"
                placeholder="Especificación de Ingrediente"
                bind:value={ingredient.especificationIngredient}
              /> <br />
            </div>
            <div>
              <h3>Especificación de Ingrediente en Ingles</h3>
              <input
                class="my-4"
                type="text"
                placeholder="Especificación de ingrediente ingles"
                bind:value={ingredient.translateSave[0].value}
              /> <br />
            </div>
          </div>
        {/each}
      </div>
    {/if}
    {#if currentStep === 4}
      <!-- Paso 4 -->
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Componente de Receta
      </h1>
      <div>
        <input
          type="text"
          placeholder="Buscar Ingredientes"
          on:input={searchIngredientComponent}
          bind:value={searchValueIngComponent}
          on:blur={closeDropdownComponent}
          on:focus={openDropdownComponent}
        />
        <div>
          {#if dropdownOpenIngComponent && ingredientsComponent.length > 0}
            <ul>
              {#each ingredientsComponent as ingredient (ingredient.id)}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <li
                  style="cursor: pointer;"
                  on:click={() => addSelectedIngredientComponent(ingredient)}
                >
                  {ingredient.name}
                </li>
              {/each}
            </ul>
          {/if}
        </div>
      </div>

      <!-- Mostrar ingredientes seleccionados -->
      <h2>Ingredientes de Componentes Seleccionados</h2>
      <div class="flex flex-wrap" style="gap: 15px;">
        {#each recetaMain.components as ingredient, index (ingredient.idIngredient)}
          <div class="shadow-2xl my-6 bg-blueGray-200 p-4 rounded-lg">
            <div class="flex items-center justify-between">
              {ingredient.name}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <span
                style="cursor: pointer; font-size: 30px; font-weight: 800; margin-left: 15px;"
                on:click={() => removeSelectedIngredientComponent(ingredient)}
                >✕</span
              >
            </div>
            <!-- Inputs para los campos adicionales -->
            <div>
              <h3 class="my-2">Componentes de Receta</h3>
              <select class="mb-2" bind:value={ingredient.idComponent}>
                {#each selectComponentsList as component}
                  <option value={component.id}>{component.name}</option>
                {/each}
              </select>
            </div>
            <div>
              <h3>Cantidad de Ingrediente</h3>
              <input
                class="my-4"
                type="number"
                placeholder="Cantidad de Ingrediente"
                bind:value={ingredient.quantity}
              /> <br />
            </div>
            <div>
              <h3>ID tipo de corte</h3>
              <select class="mb-2" bind:value={ingredient.idCutType}>
                <option value=""></option>
                {#each selectComponentsTypeCute as option}
                  <option value={option.id}>{option.name}</option>
                {/each}
              </select>
              <br />
            </div>
            <div>
              <h3>Especificación de Ingrediente</h3>
              <input
                class="my-4"
                type="text"
                placeholder="Especificación de Ingrediente"
                bind:value={ingredient.especificationIngredient}
              /> <br />
            </div>
            <div>
              <h3>Especificación de Ingrediente en Inglés</h3>
              <input
                class="my-4"
                type="text"
                placeholder="Especificación de Ingrediente en Inglés"
                bind:value={ingredient.translateSave[0].value}
              /> <br />
            </div>
          </div>
        {/each}
      </div>
    {/if}
    {#if currentStep === 5}
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Utensilios de Receta
      </h1>
      <div>
        <h2>Selecciona Utensilios</h2>
        <input
          type="text"
          placeholder="Buscar Utensilios"
          on:input={searchUtensils}
          bind:value={searchValueUtensils}
          on:focus={openDropdownUtensils}
          on:focusout={closeDropdownUtensils}
        />
        <div>
          {#if dropdownOpenUtensils && utensils.length > 0}
            {#each utensils as utensil, index (utensil.id)}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <p
                style="cursor: pointer; margin: 5px 0;"
                on:click={() => addSelectedUtensil(utensil)}
              >
                {utensil.name}
              </p>
            {/each}
          {/if}
        </div>

        <!-- Mostrar utensilios seleccionados -->
        <h2>Utensilios Seleccionados</h2>
        <div style="gap: 20px;" class="flex flex-wrap">
          {#each selectedUtensils as utensil, index}
            <div
              style="width: max-content;"
              class="shadow-2xl my-4 bg-blueGray-200 p-4 rounded-lg"
            >
              <div class="flex items-center justify-between">
                {utensil.name}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                  style="cursor: pointer; font-size: 30px; font-weight: 800; margin-left: 15px;"
                  on:click={() => removeSelectedUtensil(utensil)}
                >
                  ✕
                </span>
              </div>
              <!-- Aquí puedes agregar más detalles sobre el utensilio si es necesario -->
            </div>
          {/each}
        </div>
      </div>
    {/if}
    {#if currentStep === 6}
      <!-- Paso 6 -->
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Tipos de receta
      </h1>
      <div>
        <h2>Selecciona Tipos de Receta</h2>
        <input
          type="text"
          placeholder="Buscar Tipos de Receta"
          on:input={searchTypes}
          bind:value={searchValueType}
          on:focus={openDropdownType}
          on:focusout={closeDropdownType}
        />
        <div>
          {#if dropdownOpenType && types.length > 0}
            {#each types as type, index (type.id)}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <p
                style="cursor: pointer; margin: 5px 0;"
                on:click={() => addSelectedType(type)}
              >
                {type.name}
              </p>
            {/each}
          {/if}
        </div>

        <!-- Mostrar tipos de receta seleccionados -->
        <h2>Tipos de Receta Seleccionados</h2>
        <div style="gap: 20px;" class="flex flex-wrap">
          {#each selectedTypes as type, index}
            <div
              style="width: max-content;"
              class="shadow-2xl my-4 bg-blueGray-200 p-4 rounded-lg"
            >
              <div class="flex items-center justify-between">
                {type.name}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                  style="cursor: pointer; font-size: 30px; font-weight: 800; margin-left: 15px;"
                  on:click={() => removeSelectedType(type)}
                >
                  ✕
                </span>
              </div>
              <!-- Aquí puedes agregar más detalles sobre el tipo de receta si es necesario -->
            </div>
          {/each}
        </div>
      </div>
    {/if}
    {#if currentStep === 7}
      <!-- Paso 7 -->
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Tiempos de comida
      </h1>
      <div>
        <h2>Selecciona Tiempos de Comida</h2>
        <input
          type="text"
          placeholder="Buscar Tiempos de Comida"
          on:input={searchTimesFood}
          bind:value={searchValueTimeFood}
          on:focus={openDropdownTimeFood}
          on:focusout={closeDropdownTimeFood}
        />
        <div>
          {#if dropdownOpenTimeFood && timesFood.length > 0}
            {#each timesFood as time, index (time.id)}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <p
                style="cursor: pointer; margin: 5px 0;"
                on:click={() => addSelectedTimeFood(time)}
              >
                {time.name}
              </p>
            {/each}
          {/if}
        </div>

        <!-- Mostrar tiempos de comida seleccionados -->
        <h2>Tiempos de Comida Seleccionados</h2>
        <div style="gap: 20px;" class="flex flex-wrap">
          {#each selectedTimesFood as time, index}
            <div
              style="width: max-content;"
              class="shadow-2xl my-4 bg-blueGray-200 p-4 rounded-lg"
            >
              <div class="flex items-center justify-between">
                {time.name}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                  style="cursor: pointer; font-size: 30px; font-weight: 800; margin-left: 15px;"
                  on:click={() => removeSelectedTimeFood(time)}
                >
                  ✕
                </span>
              </div>
              <!-- Aquí puedes agregar más detalles sobre el tiempo de comida si es necesario -->
            </div>
          {/each}
        </div>
      </div>
    {/if}
    {#if currentStep === 8}
      <!-- Paso 8 -->
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Planes de Dieta
      </h1>
      <div>
        <h2>Selecciona Planes de Dieta</h2>
        <input
          type="text"
          placeholder="Buscar Planes de Dieta"
          on:input={searchDietPlans}
          bind:value={searchValueDietPlan}
          on:focus={openDropdownDietPlan}
          on:focusout={closeDropdownDietPlan}
        />
        <div>
          {#if dropdownOpenDietPlan && dietPlans.length > 0}
            {#each dietPlans as diet, index (diet.id)}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <p
                style="cursor: pointer; margin: 5px 0;"
                on:click={() => addSelectedDietPlan(diet)}
              >
                {diet.name}
              </p>
            {/each}
          {/if}
        </div>

        <!-- Mostrar planes de dieta seleccionados -->
        <h2>Planes de Dieta Seleccionados</h2>
        <div style="gap: 20px;" class="flex flex-wrap">
          {#each selectedDietPlans as diet, index}
            <div
              style="width: max-content;"
              class="shadow-2xl my-4 bg-blueGray-200 p-4 rounded-lg"
            >
              <div class="flex items-center justify-between">
                {diet.name}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                  style="cursor: pointer; font-size: 30px; font-weight: 800; margin-left: 15px;"
                  on:click={() => removeSelectedDietPlan(diet)}
                >
                  ✕
                </span>
              </div>
              <!-- Aquí puedes agregar más detalles sobre el plan de dieta si es necesario -->
            </div>
          {/each}
        </div>
      </div>
    {/if}
    {#if currentStep === 9}
      <!-- Paso 9 -->
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Categorias y sub categorias de receta
      </h1>
      <div>
        <h2>Selecciona Categoría de Sub Categoría de Receta</h2>
        <input
          type="text"
          placeholder="Buscar Categorías de Sub Categorías de Receta"
          on:input={searchCategorySubcategories}
          bind:value={searchValueCategorySubcategory}
          on:focus={openDropdownCategorySubcategory}
          on:focusout={closeDropdownCategorySubcategory}
        />
        <div>
          {#if dropdownOpenCategorySubcategory && categorySubcategoriesList.length > 0}
            {#each categorySubcategoriesList as category, index (category.id)}
              <!-- svelte-ignore a11y-click-events-have-key-events -->
              <p
                style="cursor: pointer; margin: 5px 0;"
                on:click={() => addSelectedCategorySubcategory(category)}
              >
                {category.name}
              </p>
            {/each}
          {/if}
        </div>

        <!-- Mostrar categorías de sub categorías seleccionadas -->
        <h2>Categorías de Sub Categorías Seleccionadas</h2>
        <div>
          {#each selectedCategorySubcategories as selected, index}
            <div class="shadow-2xl my-6 bg-blueGray-200 p-4 rounded-lg">
              <div class="flex items-center justify-between">
                {selected.name}
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span
                  style="cursor: pointer; font-size: 30px; font-weight: 800; margin-left: 15px;"
                  on:click={() => removeSelectedCategorySubcategory(selected)}
                >
                  ✕
                </span>
              </div>
              <!-- Aquí puedes agregar más detalles sobre la categoría de sub categoría si es necesario -->
            </div>
          {/each}
        </div>
      </div>
    {/if}
    {#if currentStep === 10}
      <!-- Paso 10 -->
      <h1
        class="w-100 text-center text-3xl font-bold mb-4"
        style="color: #89766a;"
      >
        Paso a Paso
      </h1>
      <div>
        <button
          class="px-4 py-2 rounded-lg bg-brown-500 m-2 text-white font-semibold"
          on:click={addStep}>Agregar Paso</button
        >

        <div style="gap: 20px;" class="flex flex-wrap">
          {#each recetaMain.steps as step, index}
            <!-- svelte-ignore a11y-label-has-associated-control -->
            <div
              style="width: max-content; min-width: 400px;"
              class="bg-blueGray-200 p-4 rounded-lg shadow-2xl my-6"
            >
              <h3 class="font-bold text-2xl">Paso {index + 1}</h3>
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label>Descripción:</label>
              <br />
              <input
                style="width: 100%;"
                type="text"
                bind:value={step.description}
              />
              <br />
              <label>Traducción de descripción:</label>
              <br />
              <input
                style="width: 100%;"
                class="mb-4"
                type="text"
                bind:value={step.translateSave[0].value}
              />
              <br />
              <!-- svelte-ignore a11y-label-has-associated-control -->
              <label>URL del Video en Español:</label>
              <br />
              <input
                style="width: 100%;"
                class="mb-4"
                type="text"
                bind:value={step.videoUrl}
              />
              <br />
              <label>URL del Video en Inglés:</label>
              <br />
              <input
                style="width: 100%;"
                class="mb-4"
                type="text"
                bind:value={step.videoUrlIngles}
              />
              <br />
              <button
                class="px-4 py-2 rounded-lg bg-red-500 m-2 text-white font-semibold"
                on:click={() => removeStep(index)}>Eliminar Paso</button
              >
            </div>
          {/each}
        </div>
      </div>
    {/if}

    {#if currentStep === 11}
      <div style="height: 300px;" class="w-full flex flex-col justify-center">
        <h3
          class="w-full text-center font-bold text-3xl"
          style="color: rgb(137, 118, 106);"
        >
          Ya esta Lista la receta
        </h3>
        <p class="w-full text-center font-bold">
          Si deseas hacer un ultimo cambio puedes hacerlo, si ya esta lista dale
          click al boto de enviar
        </p>
      </div>
    {/if}
  </div>

  <!-- Botones para navegar entre pasos -->
  <div class="flex justify-between mt-8">
    <!-- svelte-ignore missing-declaration -->
    <button
      style={currentStep === 1
        ? "background-color: rgb(162, 139, 125)!important;"
        : ""}
      class="px-4 py-2 bg-brown-500 text-white rounded-lg"
      on:click={prevStep}
      disabled={currentStep === 1}
    >
      Anterior
    </button>

    {#if currentStep === totalSteps}
      <div class="flex flex-wrap justify-center">
        <button
          class="px-4 py-2 bg-brown-500 text-white rounded-lg"
          on:click={enviarFunction}
        >
          ENVIAR
        </button>
        <!-- svelte-ignore a11y-missing-content -->
        <h3
          class="font-bold w-full text-center"
          style="color: rgb(162, 139, 125)!important;"
          id="mensajeEnviar"
        ></h3>
      </div>
    {/if}
    <button
      style={currentStep === totalSteps
        ? "background-color: rgb(162, 139, 125)!important;"
        : ""}
      class="px-4 py-2 bg-brown-500 text-white rounded-lg"
      on:click={nextStep}
      disabled={currentStep === totalSteps}
    >
      Siguiente
    </button>
  </div>
</div>

{#if ModalArchivosNum !== 0}
  <ModalArchivos on:close={closeModalArchivos}>
    <Multimedia />
  </ModalArchivos>
{/if}
